/* Загальні стилі */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin-top: 30px;
  font-family: Arial, sans-serif;
  color: #333;
  height: 100%;
}

a {
  text-decoration: none;
}
ul { list-style: none; }
.karma{
  margin-bottom: 15px;
  display: flex;
  font-size: 40px;
  color: rgb(0, 0, 0);
}

.about span{
  display: block;
  font-weight: 700;
}
.homepage {
  width: 100%;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #222;
  color: #fff;
  width: 100%;
  font-size: 14px;
  position: fixed;
  top: 0;  /* хедер буде прилипати до верхньої частини екрану */
  z-index: 1000;  /* щоб хедер був поверх інших елементів */
}


.logo img{
  height: 60px;
}
.logo h1 {
  font-size: 2rem;
  margin: 0;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.navigation a {
  color: #fff;
  text-decoration: none;
}

.hero {
  background: url(./assets/img/IMG_6586.jpg);
  background-position: center;
  background-size: cover;
  padding: 100px 0px;
  color: #fff;
}
.hero-content-logo{
  margin-top: 20px;
  max-width: max-content;
  width: 100%;
  margin-bottom: 30px;
}
.hero-content-text{
  font-size: 18px;
  margin-bottom: 40px;
}

.hero h2 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 200;
  text-transform: uppercase;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  background-size: cover;
  /* padding: 100px 20px; */
  color: #fff;
  text-align: center;
}

.booking-button {
  display: inline-block;
text-transform: uppercase;
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 200;

  color: black;
  background-color: white;
  border: solid 1px #bcbcbc ;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease; /* Одне оголошення transition для двох властивостей */
}

.booking-button:hover {
  background-color: #7c7b7b;
  transform: scale(1.05); /* Масштабування елементу на 5% при наведенні */
}
.about{

  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: center;
}
.about-content{
  max-width: 500px;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.about-content p {
  margin-top: 20px;
}

.services, .about, .contact {
  padding: 40px 20px;
}

.services h2, .about h2, .contact h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.service-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.service-item {
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  height: 150px;
  width: 300px;
  transition: transform 0.3s ease; /* Додаємо плавний перехід для масштабування */
}

.service-item:hover {
  transform: scale(1.05); /* Масштабуємо елемент на 5% при наведенні */
}
/* Стилі для секції послуг */
.services {
  padding: 50px 0px;
  text-align: center;
  background-color: #f4f4f4;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}


.service-item {
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  height: 150px; /* Фіксована висота для однакових карток */
  width: 300px;
}

.service-item h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.service-item p {
  flex-grow: 1;
  font-size: 1rem;
  color: #666;
}

.service-item .price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  margin-top: 10px;
}

.service-item .duration {
  font-size: 0.9rem;
  color: #888;
}

.footer {
  background-color: #222;
  color: #fff;
  padding: 20px;
  font-size: 0.9rem;
}

.footer nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.footer a {
  color: #fff;
  text-decoration: none;
}


.contact-content{
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.contact-info {
  flex: 1;
  max-width: 40%; /* Контакти займають половину ширини */
}

#map {
  max-width: 60%; /* Карта займає половину ширини */
  height: 400px; /* Висота карти */
}

/* Для мобільних пристроїв */
@media (max-width: 1000px) {
  .contact-content {
    flex-direction: column; /* На маленьких екранах блоки стають стовпцями */
    align-items: center;
  }
 

  .contact-info,
  #map {
    margin-top: 30px;
    max-width: 100%;
  }
}

.contact {
  margin: 40px 0;
  text-align: center;
}

.contact-container {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}



.contact-item {
  transition:  transform 0.3s ease; /* Одне оголошення transition для двох властивостей */
  font-style: normal;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000;
  text-decoration: none;
  font-size: 16px;
}
.contact-item:hover {
  transform: scale(1.05); /* Масштабування елементу на 5% при наведенні */
}

.contact-item svg {
  width: 20px;
  height: 20px;
}

.local {
  color: #0078d4;
}


@media (max-width: 500px) {
.logo img{
  height: 40px;
}

body, html {
  margin-top: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  height: 100%;
}
}
@media (max-width: 650px) {
.hero{
  height: 100vh;
}
}

footer li{
  margin-top: 5px;
}


.contact-content-top{
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}


.contact-info-top {
  width: 100%;
  transition:  transform 0.3s ease; /* Одне оголошення transition для двох властивостей */
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-top: 30px;
}
.contact-item-top{
  font-style: none;
  display: flex;
  color: white;
  width: 100%;
  text-align: center;
  gap: 10px;
  align-items: center;
  font-style: normal;
}
.contact-item-top:not(:first-child){
  margin-top: 10px;
}

.contact-item-top svg {
  fill: white;
  width: 20px;
  height: 20px;
}
.contact-item:hover {
  transform: scale(1.05); /* Масштабування елементу на 5% при наведенні */
}

@media(max-width: 799px){
  .header{
    flex-direction: column;
    padding: 15px 0;
    gap: 10px;
  }
}

.time{
  font-size: 18px;
  text-decoration: none;
  font-weight: 100;
  margin-top: 20px;
  
}
.time span{
  font-weight: 700;
}